.moeda-container {
    display: flex;
    justify-content: center;
    color: black;
    max-height: 800px !important;
    background-color: black;
    max-width: 80vw !important;
  }
  
  .moeda-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    width: 900px;
    background-color: black;
  }
  
  .moeda {
    display: flex;
    align-items: center;
   
    min-width: 300px;
    background-color: black;
  }
  
  .moeda h2 {
    font-size: 16px;
    width: 150px;
  }
  
  .moeda img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  
  .moeda-symbol {
    text-transform: uppercase;
  }
  
  .moeda-data {
    display: flex;
    text-align: right;
    justify-content: space-between;
    width: 100%;
    color: wheat;
  }
  
  .moeda-price {
    width: 110px;
    color: white;
  }
  
  .moeda-volume {
    width: 155px;
  }
  
  .moeda-marketcap {
    width: 230px;
  }
  
  .moeda-percent {
    width: 100px;
  }
  
  .red {
    color: #f00606;
  }
  
  .green {
    color: #1146d8;
  }
  .moeda-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: #fff;
    background-color: black;
    padding: 30px;
    border-radius: 30px;
}

.brd{
  background: rgb(79, 94, 230);
  border-radius: 10px;
  padding: 4px;
}