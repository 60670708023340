@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@font-face {
  font-family: Clip;
  src: url("https://acupoftee.github.io/fonts/Clip.ttf");
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  /* text-align: center; */
}

h2 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: 'Maven Pro', sans-serif;
  color: #000;
  font-size: 40px;
  line-height: 52px;
}
h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 10px;
}
* {
  box-sizing: border-box;
}


.home,
.reports,
.products,.dashboard,.transactions,.charts,.withdrawals,.login {
  
  height: 90vh;
 
  
  margin: 1% 5%;
  
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #0b1447;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.moeda-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  color: #fff;
}

.moeda-search {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.moeda-text {
  margin-bottom: 32px;
  text-align: center;
  color: #f38b2a;
}

.moeda-input {
  padding-left: 16px;
  width: 500px;
  outline: none;
  height: 50px;
  border: 2px solid transparent;
  border-radius: 19px;
  border-color: #f38b2a;
  background-color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1);
}

.moeda-input::-webkit-input-placeholder {
  color: rgb(170, 170, 170);
}

.moeda-input::placeholder {
  color: rgb(170, 170, 170);
}
* {
  box-sizing: border-box;
}

.login{
  
  width: 100vw;
  height: 100vh;
  margin:  auto;
display: flex;
justify-content: center;
flex-direction: column;
background-image: url(/static/media/bg.50ab9bab.jpg);
background-color:#0b1447 ;
background-repeat: no-repeat;
background-size: cover;
background-position: center;

}

.form input{
  border: none;
  border-bottom: 1px solid #000000ad;
    border-right: 1px solid #000000ad;
    width:100%;
}
.addflex{
  display: flex;
  justify-content: center;
  flex-basis: 1;
  grid-gap: 3px;
  gap: 3px;
}
 .form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  
   min-width: 400px;
   margin: auto;
   padding: 10px;
   background-color: #e6e3e33d !important;
   border-radius:10px ;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 }
 .form h3{
  text-align: center;
 }
 @media screen and (max-width: 400px) {
  .form{
    min-width: 280px;
    max-width:300px;
  }
}
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 200000000;
}
.formtxt{
  margin: 0;
  padding: 0;
} .formtxt span{
  color: red;
}
.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }
.header-card-label{
  max-width: 80%;
}
  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

.section-padding-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.custom-container {
  position: relative;
  z-index: 10;
  overflow: visible;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 60px;
  padding-left: 60px;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
@media screen and (max-width: 991px){
.w-container {
  max-width: 728px;
}}
@media screen and (max-width: 991px){
.custom-container {
    overflow: hidden;
}
}

.press-logo-box {
 display: flex;
 flex-wrap: wrap;
}
body {
  font-family: 'Maven Pro', sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 26px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: 'Maven Pro', sans-serif;
  
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: 'Maven Pro', sans-serif;
  
  font-size: 40px;
  line-height: 52px;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Maven Pro', sans-serif;
 
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
}

h4 {
  margin-top: 0px;
  margin-bottom: 6px;
  font-family: 'Maven Pro', sans-serif;
  
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
}

h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Maven Pro', sans-serif;
 
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Maven Pro', sans-serif;
 
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}

p {
  margin-bottom: 15px;
  font-family: 'Maven Pro', sans-serif;
  
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

a {
  transition: all 200ms ease;
  font-family: 'Maven Pro', sans-serif;
  color: #503ce6;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  color: #281694;
}

a:active {
  color: #503ce6;
}

a:focus {
  color: #281694;
}

a.w--current {
  color: #503ce6;
}

.custom-container {
  position: relative;
  z-index: 10;
  overflow: visible;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 60px;
  padding-left: 60px;
}

.custom-container.no-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.div-block {
  display: flex;
}

.color {
  display: flex;
  margin-right: 10px;
  margin-left: 0px;
  background-color: #c4e022;
}

.color.violet {
  background-color: #cec7ff;
}

.color.violet-gray {
  background-color: #f6f5fb;
}

.color.salmon {
  background-color: #ffbdb7;
}

.color.reliable-blue {
  background-color: #503ce6;
}

.color.crystal-cyan {
  background-color: #00e0ff;
}

.color.bravery-red {
  background-color: #f85546;
}

.color.deep-indaco {
  background-color: #281694;
}

.color.navy {
  background-color: #0c0a64;
}

.color.carminio {
  background-color: #a6131c;
}

.paragraph-emphasis {
  color: #000;
  font-size: 24px;
  line-height: 36px;
}

.cover-image {
  display: flex;
  width: 100%;
  height: 600px;
  background-image: url(/static/media/bg3.2f576bd7.jpg);
  background-size: 1330px;
  background-color: black;
}

.header-card-label {
  position: static;
  display: flex;
  max-width: 60%;
  min-width: 20%;
  align-self: center;
  order: -1;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-image: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0.9));
}

.container-title {
  display: flex;
  width: 500px;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  flex: 1 1;
  direction: ltr;
}

.container-title.card-home {
  width: 100%;
}

.header-card-container {
  display: flex;
  width: 100%;
  padding: 60px;
  flex-direction: column;
  align-self: center;
  order: -1;
  flex: 0 1 auto;
}

.button-high-emphasis {
  display: flex;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  background-color: #503ce6;
  transition: all 500ms cubic-bezier(.165, .84, .44, 1);
  font-family: 'Maven Pro', sans-serif;
  font-weight: 700;
  text-align: center;
}

.button-high-emphasis:hover {
  background-color: #281694;
  color: #fff;
}

.text-span {
  color: #503ce6;
  white-space: pre-line;
}

.crypto-explanation {
  display: flex;
  flex-direction: column;
}

.crypto-explanation-texts {
  display: flex;
  flex-direction: column;
}

.crypto-explanation-description {
  display: flex;
  flex-direction: row;
}

.crypto-explanation-title {
  display: flex;
  max-width: 80%;
  margin-bottom: 0px;
}

.crypto-explanation-details {
  display: flex;
  width: 70%;
  margin-left: 0px;
  padding-right: 0px;
}

.crypto-explanation-media {
  display: flex;
  width: 100%;
  justify-content: center;
}

.gradient-bg {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 524px;
  background-image: linear-gradient(180deg, #f6f5fb, #fff);
}

.gradient-bg.gradient-margin {
  margin-top: 120px;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fff;
}

.brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  height: 66px;
  margin-right: 120px;
  align-items: center;
  order: -1;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  align-self: center;
}

.navbar-contents {
  display: flex;
  min-height: 110px;
  justify-content: space-between;
  align-items: stretch;
}

.navbar-content-left {
  display: flex;
}

.mini-spacer {
  width: 30px;
}

.footer {
  background-color: #000;
}

.footer-container {
  display: flex;
  padding-top: 60px;
  flex-direction: column;
}

.footer-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.white-text {
  width: 100%;
  color: #fff;
}

.footer-site-map {
  display: flex;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  justify-content: space-between;
}

.footer-foot-notes {
  display: flex;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
  border-top: 1px solid hsla(0, 0%, 100%, 0.2);
}

.button-white {
  display: flex;
  padding: 20px 32px;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;
  background-color: #fff;
  transition: all 500ms cubic-bezier(.23, 1, .32, 1);
  font-family: 'Maven Pro', sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.button-white:hover {
  background-color: #eeecfe;
  color: #000;
}

.footer-logo {
  display: flex;
}

.footer-site-map-links-container {
  display: flex;
}

.footer-site-map-links-block {
  margin-left: 90px;
}

.footer-links {
  transition: all 500ms cubic-bezier(.165, .84, .44, 1);
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.footer-links:hover {
  color: #fff;
}

.footer-links.w--current {
  color: hsla(0, 0%, 100%, 0.7);
}

.footer-links.w--current:hover {
  color: #fff;
}

.contaner-reduced {
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
}

.article {
  margin-top: 20px;
  margin-bottom: 60px;
}

.tiles-with-margin {
  padding-bottom: 20px;
}

.pay-explanation-description {
  display: flex;
  width: 50%;
  padding-top: 120px;
  flex-direction: column;
}

.crypto-reinsurance-block {
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
}

.crypto-reinsurance-single {
  display: flex;
  width: 33.33%;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 30px;
  flex-direction: row;
  align-items: stretch;
}

.crypto-reinsurance-details {
  position: relative;
  padding-right: 0px;
  padding-left: 16px;
  flex: 0 1 auto;
}

.negative-margin-section {
  position: relative;
  z-index: 20;
  margin-top: -158px;
}

.image-2 {
  width: 100%;
}

.crypto-reinsurance-icon {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
}

.table--clear {
  width: 24px;
  opacity: 0.2;
}

.product-features-block {
  display: flex;
}

.crypto-knowledge {
  display: flex;
  flex-direction: column;
}

.crypto-knowledge-title {
  display: flex;
  padding-right: 0px;
}

.container-crypto-knowledge {
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
}

.crypto-knowledge-media {
  display: flex;
  overflow: visible;
  width: 100%;
  height: 60px;
  margin-top: 60px;
  margin-bottom: 90px;
  align-items: center;
}

.crypto-setup-account-step-1 {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.crypto-setup-details {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
}

.heading-5 {
  width: 500px;
}

.crypto-about-intro {
  display: flex;
  flex-direction: column;
}

.header-important {
  margin-bottom: 30px;
  flex: 0 1 auto;
  font-size: 88px;
  line-height: 88px;
}

.our-story-container {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 220px;
  padding-left: 140px;
  flex-direction: column;
}

.our-story-container.final-part {
  padding-right: 150px;
}

.our-story-text {
  display: block;
  margin-top: 0px;
  margin-bottom: 30px;
}

.our-story-text-important {
  display: flex;
  margin-top: 60px;
  margin-bottom: 90px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.vertical-block-filled {
  width: 10px;
  margin-right: 40px;
  flex: 0 0 auto;
  background-color: #503ce6;
}

.text-important {
  display: flex;
  flex: 1 1;
}

.our-story-title {
  display: flex;
  padding-top: 60px;
  padding-bottom: 30px;
}

.paragraph-emphasis {
  margin-bottom: 15px;
  font-weight: 500;
}

.paragraph-emphasis.mid---reliable-blue {
  color: #503ce6;
  font-weight: 500;
}

.about-image {
  position: relative;
  display: none;
  overflow: visible;
  width: 100%;
  max-width: 1440px;
  margin-top: 120px;
  margin-right: auto;
  margin-left: auto;
}

.heading-7 {
  padding-right: 0px;
}

.crypto-invest-bitcoin {
  display: flex;
  flex-direction: row;
}

.crypto-invest-bitcoin-detail {
  display: flex;
  width: 48%;
  flex-direction: column;
  flex: 0 0 auto;
}

.heading-8 {
  padding-right: 30px;
}

.heading-9 {
  padding-bottom: 30px;
  padding-left: 0px;
}

.crypto-invest-bitcoin-detail-explained {
  display: flex;
  width: 52%;
  margin-left: 60px;
  padding-left: 0px;
  flex-direction: column;
}

.crypto-clear-doubt-block {
  display: flex;
  flex-direction: row;
}

.text-span-3 {
  color: #503ce6;
}

.crypto-clear-doubt-title {
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  flex: 0 1 auto;
}

.crypto-clear-doubt-article {
  display: flex;
  width: 33%;
  margin-right: 10px;
  margin-left: 10px;
  flex-direction: row;
  flex: 1 1;
}

.image-5 {
  position: static;
  width: 100%;
  flex: 0 0 auto;
  object-fit: fill;
}

.blog-heading {
  margin-bottom: 10px;
  flex: 0 1 auto;
}

.crypto-clear-doubt-article-details {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.blog-label {
  margin-top: 30px;
  margin-bottom: 5px;
  opacity: 0.4;
  line-height: 28px;
  font-weight: 700;
}

.rectangle-dollar-invested {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 59px;
  padding: 60px;
  border: 1px solid transparent;
  border-radius: 28px;
  background-image: linear-gradient(270deg, #0c0a64, #281694);
}

.counter-invested-dollar {
  display: flex;
  align-self: center;
}

.text-invested-dollar {
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
  flex-direction: column;
  justify-content: center;
}

.button-container-invested-dollar {
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
  flex-direction: column;
  justify-content: center;
}

.crypto-invest-smarter {
  display: flex;
  flex-direction: column;
}

.crypto-invest-smarter-description {
  display: flex;
}

.crypto-invest-smarter-details {
  display: flex;
  width: 50%;
  padding-top: 15px;
  padding-bottom: 16px;
  flex-direction: row;
  flex: 1 1;
}

.rectangle-pay-perform {
  width: 42%;
  padding: 50px;
  border-radius: 24px;
  background-image: linear-gradient(270deg, #0c0a64, #281694);
  box-shadow: 0 40px 30px -30px rgba(40, 22, 148, 0.4);
}

.pay-perform-block {
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
}

.text-span-4 {
  color: #f85546;
}

.pay-performance {
  display: flex;
  width: 58%;
  padding-left: 80px;
}

.pay-performance-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-calculation {
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 60px;
  flex-direction: row;
  border-bottom: 1px solid #cec7ff;
}

.price-calculation-details {
  display: flex;
  width: 50%;
  flex-direction: row;
}

.price-calculation-details-explained {
  display: flex;
  flex-direction: column;
}

.paragraph-4 {
  padding-bottom: 30px;
  color: #000;
}

.crypto-advantages-block {
  display: flex;
  padding-bottom: 60px;
  flex-direction: row;
}

.crypto-advantages-single {
  display: flex;
  width: 33.33%;
}

.crypto-advantages-icon {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
}

.crypto-advantages-details {
  position: relative;
  display: block;
  padding-left: 16px;
  flex-direction: column;
  flex: 0 1 auto;
}

.plan-description-title {
  display: flex;
  width: 50%;
  padding-top: 90px;
  flex-direction: column;
  align-items: flex-start;
}

.plan-features-list {
  display: flex;
  margin-top: 60px;
  flex-direction: row;
}

.single-plan-features-details {
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;
}

.single-plan-features-details.feature-disabled {
  opacity: 0.2;
}

.single-plan-title {
  display: flex;
  padding-bottom: 60px;
  flex-direction: column;
  flex: 1 1;
}

.heading-20 {
  color: #503ce6;
}

.single-plan-feature {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 1;
}

.single-plan-feature-icon {
  display: flex;
  align-items: stretch;
  flex: 0 0 auto;
}

.single-plan-feature-details {
  padding-left: 16px;
  align-self: center;
}

.single-plan-details {
  display: flex;
  width: 50%;
  padding-right: 60px;
  flex-direction: column;
  align-items: stretch;
}

.we-are-special-block {
  margin-bottom: 30px;
}

.icons-with-background {
  width: 48px;
  padding: 8px;
  border-radius: 40px;
  background-color: #eeecfe;
}

.just-few-control-block {
  display: flex;
  margin-top: 0px;
  flex-direction: row;
  align-items: center;
}

.just-few-control-title {
  display: flex;
  min-width: 45%;
  flex-direction: column;
}

.just-few-control-animation {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.list-cards-block {
  position: relative;
  display: flex;
  width: 50%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.list-cards-block.top-padding {
  padding-top: 120px;
}

.single-card-block {
  position: static;
  display: flex;
  width: 100%;
  padding: 0px;
  flex-direction: row;
  flex: 0 0 auto;
  border-radius: 0px;
}

.single-card-block.odd-card {
  flex-direction: column;
}

.card-more-info-block {
  position: relative;
  z-index: 50;
  display: flex;
  margin-left: -12px;
  padding: 8px 12px;
  align-self: flex-start;
  flex: 0 1 auto;
  transition: all 300ms ease;
  cursor: pointer;
}

.card-more-info-block:hover {
  padding: 8px 12px;
  border-radius: 8px;
  background-color: rgba(206, 199, 255, 0.4);
}

.more-info-icon {
  display: flex;
  flex: 0 0 auto;
  cursor: pointer;
}

.more-info-block {
  display: flex;
  margin-left: 20px;
  padding-left: 0px;
  flex: 1 1;
  transition: all 300ms ease;
  cursor: pointer;
}

.more-info-link {
  align-self: center;
  color: #503ce6;
  text-decoration: none;
  cursor: pointer;
}

.card-container {
  display: flex;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 60px 60px 40px;
  flex-direction: column;
  border-radius: 24px;
  background-image: linear-gradient(180deg, #f6f5fb, #f6f5fb);
}

.card-container.even-card {
  position: static;
  display: flex;
  overflow: hidden;
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.card-container.odd-card {
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.spacer-60 {
  width: 100%;
  height: 60px;
}

.product-features-text {
  display: flex;
  width: 45%;
  flex-direction: column;
}

.product-features-image-block {
  display: flex;
  width: 55%;
  padding-left: 60px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
}

.feature-text-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  padding: 30px;
  flex-direction: column;
  border-radius: 14px;
  background-color: #f6f5fb;
  cursor: pointer;
}

.feature-text-container:hover {
  background-color: #eeecfe;
}

.feature-text-title {
  margin-bottom: 0px;
}

.product-features-img {
  display: none;
}

.product-feature-paragraph {
  display: none;
  margin-top: 12px;
}

.feature-text-container-default {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  padding: 30px;
  flex-direction: column;
  border-radius: 14px;
  background-color: #f6f5fb;
  cursor: pointer;
}

.feature-text-container-default:hover {
  background-color: #eeecfe;
}

.mini-faq {
  display: flex;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.faq-section-title {
  display: flex;
  width: 20%;
}

.mini-faq-question-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mini-faq-question {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.mini-faq-answer {
  position: static;
  display: flex;
  flex: 0 0 auto;
}

.faq-paragraph {
  margin-top: 15px;
  transition: opacity 200ms ease;
}

.mini-faq-question-container {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.mini-faq-question-icon {
  display: flex;
}

.heading-23 {
  margin-top: 15px;
}

.link {
  margin-top: 22px;
  font-size: 16px;
  line-height: 26px;
}
.bcbg,.rebg,.gdbg{
  background-image: url(/static/media/bcbg.1f32c393.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rebg{
  background-image: url(/static/media/rebg.b4594de1.jpg);
}
.gdbg{
  background-image: url(/static/media/goldbg.4d156c67.jpg);
}
.gdbg::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right,#002f4b,#dc4225);
  opacity: .6; 
  
}
.rebg::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right,#002f4b,#dc4225);
  opacity: .6; 
  
}
.bcbg::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right,#002f4b,#dc4225);
  opacity: .6; 
  
}
.body{
  overflow-x: hidden;
}
.navv{
  height: 80px;
  width: 100vw;
  background-color: #000000;
  
  display: flex;
  position: fixed;
  z-index: 90000;
}

/*Styling logo*/
.logo{
  padding:1vw 2vw;
  text-align: center;
}


/*Styling Links*/
.nav-links{
  display: flex;
  list-style: none; 
  width: 88vw;
  padding: 0 0.7vw;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  padding-top: 10px;
  font-size: 10px;
}
.nav-links li a{
  text-decoration: none;
  margin: 0 0.7vw;
  color: white;
  font-size: 10px;
}
.nav-links li a:hover {
  color: #61DAFB;
}
.nav-links li {
  position: relative;
}
.nav-links li a::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  background-color: #61DAFB;
  position: absolute;
  transition: all ease-in-out 250ms;
  margin: 0 0 0 10%;
}
.nav-links li a:hover::before{
  width: 80%;
}

/*Styling Buttons*/
.login-button{
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  font-size: 1rem;
  cursor: pointer;
  color: white;

}
.login-button:hover {
  color: #131418;
  background-color: #f2f5f7;
  border:1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}
.join-button{
  color: #131418;
  background-color: #61DAFB;
  border: 1.5px solid #61DAFB;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
}
.join-button:hover {
  color: #f2f5f7;
  background-color: transparent;
  border:1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div{
  width: 30px;
  height:3px;
  background: #f2f5f7;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger{
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 800px){
  .navv{
      position: fixed;
      z-index: 900000;
  }
  .hamburger{
      display:block;
      position: absolute;
      cursor: pointer;
      right: 5%;
      top: 50%;
      -webkit-transform: translate(-5%, -50%);
              transform: translate(-5%, -50%);
      z-index: 5;
      transition: all 0.7s ease;
  }
  .nav-links{
      position: fixed;
      background: #131418;
      height: 100vh;
      width: 100%;
      flex-direction: column;
      clip-path: circle(50px at 90% -20%);
      -webkit-clip-path: circle(50px at 90% -10%);
      transition: all 1s ease-out;
      pointer-events: none;
      z-index: 3;
      justify-content: start;
      grid-gap:40px;
      gap:40px
  }
  .nav-links.open{
      clip-path: circle(1000px at 90% -10%);
      -webkit-clip-path: circle(1000px at 90% -10%);
      pointer-events: all;
  }
  .nav-links li{
      opacity: 1;
  }
  .nav-links li:nth-child(1){
      transition: all 0.5s ease 0.2s;
  }
  .nav-links li:nth-child(2){
      transition: all 0.5s ease 0.4s;
  }
  .nav-links li:nth-child(3){
      transition: all 0.5s ease 0.6s;
  }
  .nav-links li:nth-child(4){
      transition: all 0.5s ease 0.7s;
  }
  .nav-links li:nth-child(5){
      transition: all 0.5s ease 0.8s;
  }
  .nav-links li:nth-child(6){
      transition: all 0.5s ease 0.9s;
      margin: 0;
  }
  .nav-links li:nth-child(7){
      transition: all 0.5s ease 1s;
      margin: 0;
  }
 
}
/*Animating Hamburger Icon on Click*/
.toggle .line1{
  -webkit-transform: rotate(-45deg) translate(-5px,6px);
          transform: rotate(-45deg) translate(-5px,6px);
}
.toggle .line2{
  transition: all 0.7s ease;
  width:0;
}
.toggle .line3{
  -webkit-transform: rotate(45deg) translate(-5px,-6px);
          transform: rotate(45deg) translate(-5px,-6px);
}
#msform {
	width: 400px;
	margin: 50px auto;
	text-align: center;
	position: relative;
}
#msform fieldset {
	background: white;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
	padding: 20px 30px;
	box-sizing: border-box;
	width: 80%;
	margin: 0 10%;
	
	/*stacking fieldsets above each other*/
	position: relative;
}
/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
	display: none;
}
/*inputs*/
#msform input, #msform textarea {
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-bottom: 10px;
	width: 100%;
	box-sizing: border-box;
	font-family: montserrat;
	color: #2C3E50;
	font-size: 13px;
}
/*buttons*/
#msform .action-button {
	width: 100px;
	background: #27AE60;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 1px;
	cursor: pointer;
	padding: 10px 5px;
	margin: 10px 5px;
}
#msform .action-button:hover, #msform .action-button:focus {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #27AE60;
}
/*headings*/
.fs-title {
	font-size: 15px;
	text-transform: uppercase;
	color: #2C3E50;
	margin-bottom: 10px;
}
.fs-subtitle {
	font-weight: normal;
	font-size: 13px;
	color: #666;
	margin-bottom: 20px;
}
/*progressbar*/
#progressbar {
	margin-bottom: 30px;
	overflow: hidden;
	/*CSS counters to number the steps*/
	counter-reset: step;
}
#progressbar li {
	list-style-type: none;
	color: white;
	text-transform: uppercase;
	font-size: 9px;
	width: 33.33%;
	float: left;
	position: relative;
}
#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 20px;
	line-height: 20px;
	display: block;
	font-size: 10px;
	color: #333;
	background: white;
	border-radius: 3px;
	margin: 0 auto 5px auto;
}
/*progressbar connectors*/
#progressbar li:after {
	content: '';
	width: 100%;
	height: 2px;
	background: white;
	position: absolute;
	left: -50%;
	top: 9px;
	z-index: -1; /*put it behind the numbers*/
}
#progressbar li:first-child:after {
	/*connector not needed before the first step*/
	content: none; 
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,  #progressbar li.active:after{
	background: #27AE60;
	color: white;
}
.formcnt{
  max-width: 500px;
  display: flex;
  justify-content: center;
  margin: auto;
  
  padding: 50px;
  border-radius: 10px;
}

.golden-btn + .golden-btn { margin-top: 1em; }

.golden-btn {
  display: inline-block;
  outline: none;
  font-family: inherit;
  font-size: 1em;
  box-sizing: border-box;
  border: none;
  border-radius: .3em;
  height: 2.75em;
  line-height: 2.5em;
  text-transform: uppercase;
  padding: 0 1em;
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(110,80,20,.4),
              inset 0 -2px 5px 1px rgba(139,66,8,1),
              inset 0 -1px 1px 3px rgba(250,227,133,1);
  background-image: linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07);
  border: 1px solid #a55d07;
  color: rgb(120,50,5);
  text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
  cursor: pointer;
  transition: all .2s ease-in-out;
  background-size: 100% 100%;
  background-position:center;
}
.golden-btn:focus,
.golden-btn:hover {
  background-size: 150% 150%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23),
                inset 0 -2px 5px 1px #b17d10,
                inset 0 -1px 1px 3px rgba(250,227,133,1);
  border: 1px solid rgba(165,93,7,.6);
  color: rgba(120,50,5,.8);
}
.golden-btn:active {
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(110,80,20,.4),
              inset 0 -2px 5px 1px #b17d10,
                inset 0 -1px 1px 3px rgba(250,227,133,1);
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.column {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .column {
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 768px) {
  .column {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }
}

@media (min-width: 992px) {
  .column {
      flex: 0 0 25%;
      max-width: 25%;
  }
}

.section-title {
  width: 100%;
  text-align: center;
  padding: 45px 0 30px 0;
}

.section-title::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 5px;
  left: calc(50% - 25px);
  background: #353535;
}

.section-title h1 {
  color: #353535;
  font-size: 50px;
  letter-spacing: 5px;
  margin-bottom: 5px;
}

@media(max-width: 767.98px) {
  .section-title h1 {
      font-size: 40px;
      letter-spacing: 3px;
  }
}

@media(max-width: 567.98px) {
  .section-title h1 {
      font-size: 30px;
      letter-spacing: 2px;
  }
}



.team-4 {
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.team-4:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.team-4 .team-img {
  position: relative;
  font-size: 0;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transition: all .3s;
}

.team-4:hover .team-img {
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
}

.team-4 .team-img img {
  width: 100%;
  height: auto;
}

.team-4 .team-img .team-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  transition: all .6s;
  z-index: 1;
  opacity: 0;
}

.team-4 .team-img .team-content p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
}

.team-4:hover .team-img .team-content {
  opacity: 1;
}

.team-4 .team-content {
  padding: 20px;
  text-align: center;
}

.team-4 .team-content h2 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 2px;
}

.team-4 .team-content h3 {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
}

.team-4 .team-social {
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-4 .team-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  padding: 11px 0 10px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  transition: all .3s;
}

.team-4 .team-social a.social-tw {
  background: #00acee;
}

.team-4 .team-social a.social-fb {
  background: #3b5998;
}

.team-4 .team-social a.social-li {
  background: #0e76a8;
}

.team-4 .team-social a.social-in {
  background: #3f729b;
}

.team-4 .team-social a.social-yt {
  background: #c4302b;
}

.team-4 .team-social a:last-child {
  margin-right: 0;
}

.team-4 .team-social a:hover {
  color: #ffffff;
  background: #222222;
}
.contain {
  background-color: #eee;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

div.form {
  background-color: #eee;
}
.contact-wrapper {
  margin: auto 0;
}

.submit-btn {
  float: left;
}
.reset-btn {
  float: right;
}

.form-headline:after {
  content: "";
  display: block;
  width: 10%;
  padding-top: 10px;
  border-bottom: 3px solid rgb(170, 92, 3)rgb(170, 92, 3);
}

.highlight-text {
  color: rgb(170, 92, 3)rgb(170, 92, 3);
}

.hightlight-contact-info {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5;
}

.highlight-text-grey {
  font-weight: 500;
}

.email-info {
    margin-top: 20px;
}

::-webkit-input-placeholder { /* Chrome */
  font-family: 'Roboto', sans-serif;
}

.required-input {
  color: black;
}
@media (min-width: 600px) {
  .contain {
    padding: 0;
  }
}

h3,
ul {
  margin: 0;
}

h3 {
  margin-bottom: 1rem;
}

.form-input:focus,
textarea:focus{
  outline: 1.5px solid rgb(170, 92, 3)rgb(170, 92, 3);
}

.form-input,
textarea {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.wrapper > * {
  padding: 1em;
}
@media (min-width: 700px) {
  .wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .wrapper > * {
    padding: 2em 2em;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.contacts {
  color: #212d31;
}

.form {
  background: #fff;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
}
form label {
  display: block;
}
form p {
  margin: 0;
}

.full-width {
  grid-column: 1 / 3;
}

button,
.submit-btn,
.form-input,
textarea {
  padding: 1em;
}

button, .submit-btn {
  background: transparent;
  border: 1px solid rgb(170, 92, 3)rgb(170, 92, 3);
  color: rgb(170, 92, 3)rgb(170, 92, 3);
  border-radius: 15px;
  padding: 5px 20px;
  text-transform: uppercase;
}
button:hover, .submit-btn:hover,
button:focus , .submit-btn:focus{
  background: rgb(170, 92, 3)rgb(170, 92, 3);
  outline: 0;
  color: rgb(221, 166, 64);
}
.error {
  color: rgb(170, 92, 3)rgb(170, 92, 3);
}

.carousel-wrapper {
  width: 520px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  border-radius: 20px;
  margin: 100px auto 20px;
}

.carousel-container {
  width: 400px;
  height: 250px;
  overflow: hidden;
  margin: 0 auto;
}

.carousel {
  display: flex;
  width: 1200px;
  -webkit-animation: sliding 12s infinite;
          animation: sliding 12s infinite;
}
.carousel div {
  width: 400px;
  height: 250px;
  background-size: cover;
  background-position: center;
}
.carousel:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.carousel .image-one {
  background-image: url("https://i.postimg.cc/9FsL1H7m/pexels-fauxels-3184405.jpg");
}
.carousel .image-two {
  background-image: url("https://i.postimg.cc/zf2pQytg/pexels-nappy-936137.jpg");
}
.carousel .image-three {
  background-image: url("https://i.postimg.cc/pXv7zngW/pexels-lukas-590041.jpg");
}

@-webkit-keyframes sliding {
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  35% {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  65% {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  70% {
    -webkit-transform: translateX(-800px);
            transform: translateX(-800px);
  }
  98% {
    -webkit-transform: translateX(-800px);
            transform: translateX(-800px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes sliding {
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  35% {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  65% {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  70% {
    -webkit-transform: translateX(-800px);
            transform: translateX(-800px);
  }
  98% {
    -webkit-transform: translateX(-800px);
            transform: translateX(-800px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media screen and (max-width: 768px) {
  .carousel-wrapper {
    width: 312px;
    height: 210px;
  }
.sign{
  font-size: 0.5em;
  flex-direction: column;
}
  .carousel-container {
    width: 240px;
    height: 150px;
  }

  .carousel {
    width: 720px;
  }
  .carousel > div {
    width: 240px;
    height: 150px;
  }

  @-webkit-keyframes sliding {
    30% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    35% {
      -webkit-transform: translateX(-240px);
              transform: translateX(-240px);
    }
    65% {
      -webkit-transform: translateX(-240px);
              transform: translateX(-240px);
    }
    70% {
      -webkit-transform: translateX(-480px);
              transform: translateX(-480px);
    }
    98% {
      -webkit-transform: translateX(-480px);
              transform: translateX(-480px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes sliding {
    30% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    35% {
      -webkit-transform: translateX(-240px);
              transform: translateX(-240px);
    }
    65% {
      -webkit-transform: translateX(-240px);
              transform: translateX(-240px);
    }
    70% {
      -webkit-transform: translateX(-480px);
              transform: translateX(-480px);
    }
    98% {
      -webkit-transform: translateX(-480px);
              transform: translateX(-480px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
}

.style-2 {
  border:3px solid #000;
  color:#000;
  font:12px Montserrat;
  text-transform:uppercase;  
  padding:10px;
  font-weight:600;
  max-width:250px; /* max width of button */
  display:block;
  text-align:left;  
  margin:10px auto;
  position:relative;
  text-decoration: none;
}
.style-2:before {
  content:">";
  position:absolute;
  right:0;
  top:0;
  background:#000;
  color:#fff;
  font-size:20px;
  width:35px;
  height:100%;
  line-height:31px;
  text-align:center;
  font-family:consolas;
  padding-right:15px;
  transition: all 0.3s ease-out;
}
.style-2:hover:before {
  padding-right:0;
  transition: all 0.3s ease-out;
}
.style-2:hover {
  background:teal;
  border-color:#000;
  color:#000;
}
.erb:hover{
  background-color: teal !important;
}
.erb{
min-width: 250px;
height:80px;
font-weight: bold;
text-transform: uppercase;

}
.navar img{
  margin: auto;
}
.form-popup {
  display: none;
  position: fixed;
  top: 70px;
  right: 0;
  border: 3px solid #f1f1f1;
  z-index: 900;
  background-color: #f6f5fb;
}

/* Add styles to the form container */
.form-container {
  max-width: 350px;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}

/* Full-width input fields */
.form-container input[type=text], .form-container input[type=password] {
  width: 100%;
  padding: 15px;
  
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}
.radio input{
  width:auto;
}
.site-footer
{
  background-color:#000000;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
  margin-top: 20px;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
@media (max-width:820px)
{
.mttop{
  margin-top: 130px;
}}
.bdy {
 
  background-color: #141114;
  background-image: linear-gradient(335deg, black 23px, transparent 23px),
    linear-gradient(155deg, black 23px, transparent 23px),
    linear-gradient(335deg, black 23px, transparent 23px),
    linear-gradient(155deg, black 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
  max-width: 80%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top:20px ;
  padding: 5px;
}

.sign {
 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: radial-gradient(
    ellipse 50% 35% at 50% 50%,
    #f37ead,
    transparent
  );
 
  letter-spacing: 2;
  max-width: 80%;
  
  font-family: "Clip";
  text-transform: uppercase;
  font-size: 1em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  -webkit-animation: shine 2s forwards, flicker 3s infinite;
          animation: shine 2s forwards, flicker 3s infinite;
}

@-webkit-keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #e05ba7;
    text-shadow: none;
  }
  82%,
  97% {
    color: #f37ead;
    text-shadow: none;
  }
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #e05ba7;
    text-shadow: none;
  }
  82%,
  97% {
    color: #f37ead;
    text-shadow: none;
  }
}

.flicker {
  -webkit-animation: shine 2s forwards, blink 3s 2s infinite;
          animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  -webkit-animation: shine 2s forwards, blink 10s 1s infinite;
          animation: shine 2s forwards, blink 10s 1s infinite;
}

@-webkit-keyframes shine {
  0% {
    color: #f37ead;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #f37ead, 0 0.5rem 3rem #f37ead;
  }
}

@keyframes shine {
  0% {
    color: #f37ead;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #f37ead, 0 0.5rem 3rem #f37ead;
  }
}

@-webkit-keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
 .bdy{
  margin-top: 113px;
 }
.sign{
  font-size: 0.8em;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
 
}
}
:root {
  --navBg: #132897;;
}

.navbar {
  background-color: #132897;
  height: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #132897;
  background-color: var(--navBg);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 100000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  background-color: #132897;
  background-color: var(--navBg);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.moeda-container {
    display: flex;
    justify-content: center;
    color: black;
    max-height: 800px !important;
    background-color: black;
    max-width: 80vw !important;
  }
  
  .moeda-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    width: 900px;
    background-color: black;
  }
  
  .moeda {
    display: flex;
    align-items: center;
   
    min-width: 300px;
    background-color: black;
  }
  
  .moeda h2 {
    font-size: 16px;
    width: 150px;
  }
  
  .moeda img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  
  .moeda-symbol {
    text-transform: uppercase;
  }
  
  .moeda-data {
    display: flex;
    text-align: right;
    justify-content: space-between;
    width: 100%;
    color: wheat;
  }
  
  .moeda-price {
    width: 110px;
    color: white;
  }
  
  .moeda-volume {
    width: 155px;
  }
  
  .moeda-marketcap {
    width: 230px;
  }
  
  .moeda-percent {
    width: 100px;
  }
  
  .red {
    color: #f00606;
  }
  
  .green {
    color: #1146d8;
  }
  .moeda-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: #fff;
    background-color: black;
    padding: 30px;
    border-radius: 30px;
}

.brd{
  background: rgb(79, 94, 230);
  border-radius: 10px;
  padding: 4px;
}
